.MiniLoader,
.MiniLoader:before,
.MiniLoader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.MiniLoader {
    color: rgb(0, 0, 0);
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.MiniLoader:before,
.MiniLoader:after {
    content: '';
    position: absolute;
}

.MiniLoader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}

.MiniLoader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }

    40% {
        box-shadow: 0 2.5em 0 0
    }
}